import * as React from 'react';

interface VideoViewerProps {
    title: string;
    src: string;
    onClose: () => void;
}

export function VideoViewer(props: VideoViewerProps) {
    return (
        <div className="videoPlayer">
            <div className="videoPlayerTopBar">
                <span>{props.title}</span>
                <button className="videoPlayerCloseIcon"
                    onClick={() => props.onClose()}
                    title='Close'>×</button>
            </div>
            <video controls autoPlay>
                <source src={props.src} />
            </video>
        </div>
    );
}