import * as React from "react";
import { Table, TableHeader, TableRow, TableHeaderCell, TableCell, TableBody, SearchBox, Button } from '@fluentui/react-components';

import './index.css';
import { GenealogieData } from "./GenealogieData";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const initialParameterName = "initiale";

function shouldBold(bold: boolean, children:JSX.Element): JSX.Element {
    return bold? <b>{children}</b> : <span>{children}</span>;
}

function filterGenealogieData(genealogieData: GenealogieData, searchWord: string): string[] {
    const allNames: string[] = Object.keys(genealogieData._patronyms);
    return allNames.filter(name => name.toLowerCase().includes(searchWord.toLowerCase()));
}

export function GenealogieLayout() {
    const [searchWord, setSearchWord] = useState(localStorage.getItem('searchValue') || '');

    const [genealogieData, setGenealogieData] = useState<GenealogieData>();
    useEffect(() => {
      const fetchData = async () => { setGenealogieData(await GenealogieData.getInstance()); };
      fetchData();
    }, []); 

    if (genealogieData === undefined)
        return <span>Chargement des données...</span>;

    const onSearchChange = (event: any ) => {
        const value = event.target.value == null ? '' : event.target.value;
        localStorage.setItem('searchValue', value);
        setSearchWord(value);
      };

    return (
    <div className='genealogiePageV2'>
        <SearchBox placeholder="Patronyme..." value={searchWord} onChange={onSearchChange} />
        {searchWord.length >= 3 && <div>
            {filterGenealogieData(genealogieData, searchWord).map(patronym => (
                <Button>
                    <Link to={"/Genealogie/Patronyme/" + patronym}>
                        {shouldBold(
                            genealogieData._patronyms[patronym].individualIds.length >= 10, 
                            <span>{patronym} ({ genealogieData._patronyms[patronym].individualIds.length })</span>)}
                    </Link>
                </Button>
            ))}
        </div>}
    </div>
    );
}
