import * as React from "react";

export interface MonnaiesThumbProps {
    hasRevers: boolean;
    droitThumb: string;
    reversThumb: string;
    fullSizeUrls: string[];
    droitIndex: number;
    reversIndex: number;
    description: string;
    showFullSize: (urls: string[], currentIndex: number) => void;
    thumbSize: number;
}

export function MonnaiesThumb(props: MonnaiesThumbProps) {
    const onClickThumbnail = (showDroit: boolean) => {
        props.showFullSize(props.fullSizeUrls, showDroit ? props.droitIndex : props.reversIndex);
    }

    const renderThumbnail = (showDroit: boolean): JSX.Element => {
        const divStyle = {
            width: props.thumbSize,
            height: props.thumbSize
        };
        const desc = props.description.replace(/¶/g, "\n");
        return <div style={divStyle}><img className="oneMonnaieThumbnail" src={showDroit ? props.droitThumb : props.reversThumb} title={desc} onClick={() => onClickThumbnail(showDroit)} alt="" /></div>;
    }

    const renderOneOrTwoSidesThumbnail = (hasRevers: boolean): JSX.Element => {
        return <div className="oneOrTwoThumbnails">{ renderThumbnail(true) }{ hasRevers && renderThumbnail(false) }</div>;
    }

    return renderOneOrTwoSidesThumbnail(props.hasRevers);
}
