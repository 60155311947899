import * as React from 'react';
import './index.css';
import { Link } from "react-router-dom";

export interface VerticalNavPaneItem {
    indentLevel: number;
    label: string;
    count?: number;
    navigateTo?: string;
}

interface VerticalNavPaneProps {
    paneItems: VerticalNavPaneItem[];
    hide: () => void;
}

export function VerticalNavPane(props: VerticalNavPaneProps) {
    return (<div className='verticalNavPane'>
        {props.paneItems.map(item => {
            const headerClass = "headerDefault header" + item.indentLevel;
            const linkClass = "linkDefault link" + item.indentLevel;
            return item.navigateTo === undefined ?
                <span className={headerClass}>{item.label}</span> :
                <div><Link className={linkClass} to={item.navigateTo} onClick={props.hide}>{item.label}</Link><span className="paneLinkCount">{item.count}</span></div>;
        })}
    </div>
    );
}
