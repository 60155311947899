import * as React from "react";
import "./index.css";
import { GenealogieData, Individual, Event } from "./GenealogieData";
import { Link, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  TableColumnDefinition,
  TableColumnSizingOptions,
} from "@fluentui/react-components";

function getYear(event: Event | undefined) {
  if (event == undefined) return "0000";
  const year = event.year();
  return year.length === 0 ? "0000" : year;
}

function getYearOfBirthForSorting(individual: Individual) {
  const year = getYear(individual.getBirthEvent());
  return year === "0000" ? getYear(individual.getDeathEvent()) : year;
}

const columns: TableColumnDefinition<Individual>[] = [
  createTableColumn<Individual>({
    columnId: "firstName",
    compare: (a, b) => a.firstName.localeCompare(b.firstName),
    renderHeaderCell: (data) => (
      <span>
        <b>Prénom</b>
      </span>
    ),
    renderCell: (individual) => (
      <span>
        <Link to={"/Genealogie/Individu/" + individual?.id}>
          {individual.firstName}{" "}
        </Link>
      </span>
    ),
  }),
  createTableColumn<Individual>({
    columnId: "years",
    compare: (a, b) =>
      getYearOfBirthForSorting(a).localeCompare(getYearOfBirthForSorting(b)),
    renderHeaderCell: (data) => (
      <span>
        <b>Années</b>
      </span>
    ),
    renderCell: (individual) => <span>{individual.getShortDates()}</span>,
  }),
];

const columnSizingOptions: TableColumnSizingOptions = {
  ["firstName"]: { minWidth: 100, idealWidth: 200 },
  ["years"]: { minWidth: 200, idealWidth: 500 },
};

export function GenealogiePatronymLayout() {
  const lastName = useParams()["lastname"] as string;
  const [genealogieData, setGenealogieData] = useState<GenealogieData>();

  useEffect(() => {
    const fetchData = async () => { setGenealogieData(await GenealogieData.getInstance()); };
    fetchData();
  }, []); 

  const individualsForPatronym = useMemo<Individual[]>(() => {
    if (!genealogieData)
        return [];
    const patronyms = genealogieData._patronyms[lastName];
    return !patronyms
      ? []
      : patronyms.individualIds
          .map((id) => genealogieData?._individuals[id])
          .sort((a, b) =>
            (a as Individual).firstName.localeCompare(
              (b as Individual).firstName
            )
          );
  }, [lastName, genealogieData]);

  if (genealogieData === undefined)
    return <span>Chargement des données...</span>;

  if (individualsForPatronym.length === 0)
    return <span>Patronyme {lastName} inconnu</span>;

  return (
    <div className="genealogiePatronymPage">
      <h1>{lastName}</h1>
      <DataGrid
        className="datagrid"
        sortable={true}
        resizableColumns={true}
        columnSizingOptions={columnSizingOptions}
        columns={columns}
        items={individualsForPatronym}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Individual>>
          {({ item, rowId }) => (
            <DataGridRow<Individual> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  );
}
