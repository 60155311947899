import { TabularData } from "./TabularData";

export enum MonnaiesData {
    Name,
    ARevers,
    Nouveau,
    Type,
    Description,

    // Added fields
    DroitThumb,
    ReversThumb,
    Droit,
    Revers,
    DroitIndex,
    ReversIndex
}

export class MonnaiesDataUtil {
    public static readonly FullSizeAzureFolder = "https://millot-blobs-cdn.azureedge.net/photos/monnaies-";
    public static readonly ThumbnailAzureFolder = "https://millot-blobs-cdn.azureedge.net/thumbnails/monnaies-";

    static HasRevers = (row: string[]) => { return row[MonnaiesData.ARevers] === null || row[MonnaiesData.ARevers] !== 'Non'; }

    static setMonnaiesUrls(monnaies: string[][]) {
        monnaies.forEach((row: string[]) => {
            let suffix: string = "";
            if (MonnaiesDataUtil.HasRevers(row)) {
                suffix = "d";
                row[MonnaiesData.Revers] = MonnaiesDataUtil.FullSizeAzureFolder + row[MonnaiesData.Name].toLowerCase() + "r.jpg";
                row[MonnaiesData.ReversThumb] = MonnaiesDataUtil.ThumbnailAzureFolder + row[MonnaiesData.Name].toLowerCase() + "r.thumb.jpg";
            }
            row[MonnaiesData.Droit] = MonnaiesDataUtil.FullSizeAzureFolder + row[MonnaiesData.Name].toLowerCase() + suffix + ".jpg";
            row[MonnaiesData.DroitThumb] = MonnaiesDataUtil.ThumbnailAzureFolder + row[MonnaiesData.Name].toLowerCase() + suffix + ".thumb.jpg";
        });
    }
}