import * as React from "react";
import './index.css';
import { useEffect, useState } from "react";

const FilmsUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/movies.htm";

export function FilmsLayout() {
    const [rawHtml, setrawHtml] = useState("");

    useEffect(() => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", FilmsUrl, true);
        xhr.onload = () => {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(xhr.responseText, 'text/html');
            const wordSectionElement = htmlDoc.getElementsByClassName("WordSection1")[0]
            setrawHtml(wordSectionElement.innerHTML);
        };
        xhr.send();
}, []);

    return <div className='filmsPage' dangerouslySetInnerHTML={{ __html: rawHtml }} />
}
