import * as React from "react";
import './index.css';
import { useEffect, useState } from "react";

const MusicUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/music.html";

export function MusicLayout() {
    const [rawHtml, setrawHtml] = useState("");

    useEffect(() => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", MusicUrl, true);
        xhr.onload = () => {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(xhr.responseText, 'text/html');
            const musicSectionElement = htmlDoc.getElementsByTagName("BODY")[0];
            setrawHtml(musicSectionElement.innerHTML);
        };
        xhr.send();
    }, []);
    return rawHtml == "" ? <div>Chargement...</div> : <div className='musicPage' dangerouslySetInnerHTML={{ __html: rawHtml }} />
}
