import * as React from "react";
import './index.css';
import { GenealogieData, Individual, Event } from "./GenealogieData";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RenderIndividualById } from "./GenealogieIndividuLayout";

function getAncestors(genealogieData: GenealogieData, id: string, ancestors: string[]) {
    const individual = genealogieData._individuals[id];
    if (individual === null || individual === undefined || !individual.familyIdAsChild)
        return;

    const parentFamily = genealogieData._families[individual.familyIdAsChild];
        if (parentFamily.husbandId != "") {
            ancestors.push(parentFamily.husbandId);
            getAncestors(genealogieData, parentFamily.husbandId, ancestors);
        }
        if (parentFamily.wifeId != "") {
            ancestors.push(parentFamily.wifeId);
            getAncestors(genealogieData, parentFamily.wifeId, ancestors);
        }
}

function getAncestorSequence(genealogieData: GenealogieData, id1: string, id2: string, sequence: string[]): boolean {
    const individual = genealogieData._individuals[id1];
    if (individual === null || individual === undefined)
        return false;

    sequence.push(id1);

    if (id1 === id2)
        return true;

    if (individual.familyIdAsChild) {
        const parentFamily = genealogieData._families[individual.familyIdAsChild];
        if (parentFamily.husbandId != "") {
            if (getAncestorSequence(genealogieData, parentFamily.husbandId, id2, sequence))
                return true;
        }
        if (parentFamily.wifeId != "") {
            if (getAncestorSequence(genealogieData, parentFamily.wifeId, id2, sequence))
                return true;
        }
    }

    sequence.pop();
    return false;
}

function RenderAncestorSequences(genealogieData: GenealogieData, id: string, commonAncestor: string) {
    let sequence: string[] = [];
    getAncestorSequence(genealogieData, id, commonAncestor, sequence);
    return <ul>{sequence.map(id => <li>{RenderIndividualById(genealogieData, id)}</li>)}<p /></ul>;
}


function findCommonAncestors(genealogieData: GenealogieData, id1: string, id2: string) {
    let ancestors1: string[] = [];
    getAncestors(genealogieData, id1, ancestors1);

    let ancestors2: string[] = [];
    getAncestors(genealogieData, id2, ancestors2);

    const commonAncestorsTotal = ancestors1.filter(id1 => ancestors2.some(id2 => id1 === id2));

    let commonAncestorsFiltered = commonAncestorsTotal.map(id => ({id:id, processed:false}));

    // remove duplicates
    commonAncestorsFiltered = commonAncestorsFiltered.filter((ancestor, index, self) =>
        index === self.findIndex((a) => a.id === ancestor.id));

    // remove ancestors of each other in list
    for(;;) {
        const current = commonAncestorsFiltered.find(ancestor => !ancestor.processed);
        if (current === undefined)
            break;

        current['processed'] = true;

        let ancestorsForCurrent: string[] = [];
        getAncestors(genealogieData, current['id'], ancestorsForCurrent);
        commonAncestorsFiltered = commonAncestorsFiltered.filter(ancestor => !ancestorsForCurrent.some(id => id === ancestor.id));
    }

    // display ancestor sequences
    return <div>
        <div>Ancêtres communs:</div>< p/>
        {
            commonAncestorsFiltered.map
            (
                ancestor => 
                    <div>
                    <b>{RenderIndividualById(genealogieData, ancestor.id)}<p /></b>
                    {RenderAncestorSequences(genealogieData, id1, ancestor.id)}
                    {RenderAncestorSequences(genealogieData, id2, ancestor.id)}
                    </div>
                
            )
        }
    </div>;
}

// http://localhost:3000/Genealogie/AncetresCommuns/?id1=I4933&id2=I64424
export function GenealogieAncetresCommunsLayout() {
    const [searchParams, setSearchParams] = useSearchParams();
    const id1 = searchParams.get("id1") as string;
    const id2 = searchParams.get("id2") as string;

    const [genealogieData, setGenealogieData] = useState<GenealogieData>();
    useEffect(() => {
      const fetchData = async () => { setGenealogieData(await GenealogieData.getInstance()); };
      fetchData();
    }, []); 

    if (genealogieData === undefined)
        return <span>Chargement des données...</span>;

    return <div className='genealogieAncetresCommunsPage'>
        <b>{RenderIndividualById(genealogieData, id1)}<p />
           {RenderIndividualById(genealogieData, id2)}<p /></b>
        {findCommonAncestors(genealogieData, id1, id2)}</div>
};

