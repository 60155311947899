import { TabularData } from "./TabularData";

const birthString = "Naissance";
const deathString = "Décès";
const baptismString = "Baptême";

function getYearFromDate(date: string) {
    return date.length < 4 ? "" : date.slice(date.length - 4);
}

function splitField(field: string): string[] {
    if (field.length === 0)
        return [];
    return field.split(';');
}

export class EventAssociation {
    individualId: string;
    type: string;
    note: string;
    constructor(individualId: string,type: string, note: string) {
        this.individualId = individualId;
        this.type = type;
        this.note = note;
    }
}

export class Event {
    type: string;
    date: string;
    place: string;
    note: string;
    associations: EventAssociation[];

    constructor(type: string, date: string, place: string, note: string, associations: EventAssociation[]) {
        this.type = type;
        this.place = place;
        this.date = date.length >= 4 ? date : "";
        this.note = note;
        this.associations = associations;
    }
    public isEmpty(): boolean { return this.date.length == 0 && this.note.length == 0 && this.place.length == 0 }
    public formatShortDateEvent(): string { return `${getYearFromDate(this.date)}${this.place.length > 0 ? " à "+ this.place : ""}`; }
    public year(): string { return getYearFromDate(this.date) };
}

export class Individual {
    constructor(id: string,
        firstName: string,
        lastName: string,
        job: string,
        familyIdAsChild: string,
        familyIdsAsSpouse: string[],
        events: Event[]) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.job = job;
        this.familyIdAsChild = familyIdAsChild;
        this.familyIdsAsSpouse = familyIdsAsSpouse;
        this.events = events;
    }
    id: string;
    firstName: string;
    lastName: string;
    job: string;
    events: Event[];
    familyIdAsChild: string;
    familyIdsAsSpouse: string[];

    public getShortDates(): string {
        const birthOrBaptism = this.getBirthOrBaptismEvent();
        const death = this.getDeathEvent();
        return `${birthOrBaptism != undefined ? birthOrBaptism.formatShortDateEvent() : ""} - ${death != undefined ? death.formatShortDateEvent() : ""}`;
    }

    public getBirthEvent(): Event|undefined { return this.events.find(e => e.type == birthString); }
    public getDeathEvent(): Event|undefined { return this.events.find(e => e.type == deathString); }
    public getBaptismEvent(): Event|undefined { return this.events.find(e => e.type == baptismString); }
    public getBirthOrBaptismEvent(): Event|undefined { const birth = this.getBirthEvent(); return birth != undefined ? birth : this.getBaptismEvent(); }
}

export class Family {
    constructor(id: string,
        husbandId: string,
        wifeId: string,
        childrenIds: string[],
        events: Event[]) {
        this.id = id;
        this.husbandId = husbandId;
        this.wifeId = wifeId;
        this.childrenIds = childrenIds;
        this.events = events;
    }
    id: string;
    husbandId: string;
    wifeId: string;
    childrenIds: string[];
    events: Event[];
}

export class Patronym {
    name: string = "";
    individualIds: string[] = [];
}

export class GenealogieData {
    private static readonly patronymsUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/genealogy_patronyms_4.tsv";
    private static readonly individualsUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/genealogy_individuals_4.tsv";
    private static readonly familiesUrl = "https://millot-blobs-cdn.azureedge.net/reactmillot/genealogy_families_4.tsv";
    public _patronyms: { [name: string]: Patronym } = {};
    public _individuals: { [id: string]: Individual } = {};
    public _families: { [id: string]: Family } = {};

    private static _genealogieData: GenealogieData;

    static async getInstance(): Promise<GenealogieData> {
        if (GenealogieData._genealogieData === undefined) {
            GenealogieData._genealogieData = new GenealogieData();
            await GenealogieData._genealogieData._init();
        }
        return GenealogieData._genealogieData;
    }

    private readEvents(eventColumns: string[]): Event[] {
        let events: Event[] = [];
        let i = 0;
        for (;;) {
            if (eventColumns.length <= i)
                break;
            const type = eventColumns[i++];
            const date = eventColumns[i++];
            const place = eventColumns[i++];
            const note = eventColumns[i++];
            const associationCount = Number.parseInt(eventColumns[i++]);
            let associations: EventAssociation[] = [];
            for (let j = 0; j < associationCount; j++) {
                associations.push({ individualId: eventColumns[i++], type: eventColumns[i++], note: eventColumns[i++] });
            }
            events.push(new Event(type, date, place, note, associations));
        }

        return events;
    }

    private async _init() {
        const patronymList = await TabularData.fetchFromUrl(GenealogieData.patronymsUrl, false);
        const individualList = await TabularData.fetchFromUrl(GenealogieData.individualsUrl, false);
        const familyList = await TabularData.fetchFromUrl(GenealogieData.familiesUrl, false);

        patronymList.forEach((p: string[]) => {
            const patronym = p[0];
            this._patronyms[patronym] = { name: patronym, individualIds: p[1].split(';') };
        });

        individualList.forEach((row: string[]) => {
            this._individuals[row[0]] = new Individual(row[0], row[1], row[2], row[3], row[4], splitField(row[5]), this.readEvents(row.slice(6)));
        });

        familyList.forEach((row: string[]) => {
            this._families[row[0]] = new Family(row[0], row[1], row[2], splitField(row[3]), this.readEvents(row.slice(4)));
        });
    }
}
