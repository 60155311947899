import * as React from 'react';
import { useState, useEffect } from 'react';

export enum PhotoViewerBackground { white, black }

export interface PhotoViewerImageDef {
    url: string;
    width?: number;
    height?: number;
}

interface PhotoViewerProps {
    imageDefs: PhotoViewerImageDef[];
    initialCurrentIndex: number;
    onClose: () => void;
    backgroundColor: PhotoViewerBackground;
}

function getScaledUrl(fullSizeUrl: string, scale: string): string {
    const beforeExt = fullSizeUrl.lastIndexOf('.');
    return encodeURI(fullSizeUrl.substring(0, beforeExt) + '.' + scale + fullSizeUrl.substring(beforeExt)).replace('/photos/', '/thumbnails/');
}

function getThumbWidth(imageDef: PhotoViewerImageDef, maxDim: number): string {
    if (imageDef.width === undefined || imageDef.height === undefined || imageDef.width > imageDef.height)
        return maxDim.toFixed();

    return (maxDim * imageDef.width / imageDef.height).toFixed();
}

function getSrcSetParam(imageDef: PhotoViewerImageDef): string {
    const fullSizeUrl = imageDef.url;
    return getScaledUrl(fullSizeUrl, "2048") + " " + getThumbWidth(imageDef, 2048) + "w, " +
        getScaledUrl(fullSizeUrl, "1024") + " " + getThumbWidth(imageDef, 1024) + "w, " +
        getScaledUrl(fullSizeUrl, "512") + " " + getThumbWidth(imageDef, 512) + "w, " +
        getScaledUrl(fullSizeUrl, "thumb") + " " + getThumbWidth(imageDef, 256) + "w";
}

export function PhotoViewer(props: PhotoViewerProps) {
    const [currentIndex, setCurrentIndex] = useState(props.initialCurrentIndex);

    const navigateToNextFullSizeImage = (next: boolean) => {
        const imageCount = props.imageDefs.length;
        const oldCurrentIndex = currentIndex;
        const newCurrentIndex = next ? oldCurrentIndex === imageCount - 1 ? 0 : oldCurrentIndex + 1 : oldCurrentIndex === 0 ? imageCount - 1 : oldCurrentIndex - 1;
        setCurrentIndex(newCurrentIndex);
    }

    const onKeyPressed = (event: KeyboardEvent) => {
        switch (event.key) {
            case "ArrowLeft":
                navigateToNextFullSizeImage(false);
                break;
            case "ArrowRight":
                navigateToNextFullSizeImage(true);
                break;
            case "Escape":
                props.onClose();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyPressed);
        return () => { document.removeEventListener("keydown", onKeyPressed); };
    });

    const onDownload = (fullSizeUrl: string) => { window.open(fullSizeUrl); };
    const onFullScreen = () => { document.getElementById("fullSizeImage")?.requestFullscreen(); };

    const fullSizeUrl = props.imageDefs[currentIndex].url;
    const title = fullSizeUrl.substring(fullSizeUrl.lastIndexOf('/') + 1);
    const srcSetParam = getSrcSetParam(props.imageDefs[currentIndex]);
    const fullSizeBackgroundDivClass = props.backgroundColor === PhotoViewerBackground.white ? "whiteBackground" : "blackBackground";
    const fullSizeImgClass = props.backgroundColor === PhotoViewerBackground.white ? "fullSizeImgWhiteBackground" : "fullSizeImgBlackBackground";

    return <div className={"fullSizeImageBackground " + fullSizeBackgroundDivClass} id="fullSizeImage">
        <img className={"fullSizeImg " + fullSizeImgClass} srcSet={srcSetParam} src={props.imageDefs[currentIndex].url} title={title} alt="" />
        <div className="fullSizeImageButtons">
            <button onClick={() => onDownload(fullSizeUrl)} title='Download'>⭳</button>
            <button onClick={() => onFullScreen()} title='Fullscreen'>⛶</button>
            <button onClick={() => props.onClose()} title='Close'>×</button>
        </div>
        <button className="nextButton" onClick={() => navigateToNextFullSizeImage(true)} title='Next'>⇨</button>
        <button className="previousButton" onClick={() => navigateToNextFullSizeImage(false)} title='Previous'>⇦</button>
    </div>;
}
