import { TabularData } from "./TabularData";

enum PhotosKeywordData {
    id,
    keyword
    // Added fields
}

enum PhotosKeywordsImagesData {
    keywordId,
    imageId
}
enum PhotosImageData {
    id,
    path,
    width,
    height
}

export class Photo {
    id = "";
    thumbPath = "";
    path = "";
    width = 0;
    height = 0;
}

export class Keyword {
    id = "";
    name = "";
    photoIds: string[] = []
}

export class PhotosData {
    private static readonly ImageListUrl = "https://millot-blobs-cdn.azureedge.net/photos/images.tsv";
    private static readonly KeywordsListUrl = "https://millot-blobs-cdn.azureedge.net/photos/keywords.tsv";
    private static readonly KeywordsImagesListUrl = "https://millot-blobs-cdn.azureedge.net/photos/keywordsimages.tsv";
    private static readonly RecentKeywordIdListUrl = "https://millot-blobs-cdn.azureedge.net/photos/recentkeywords.tsv";
    public static readonly ThumbnailAzureFolder = "https://millot-blobs-cdn.azureedge.net/thumbnails/";
    public static readonly FullSizeAzureFolder = "https://millot-blobs-cdn.azureedge.net/photos/";

    private static _instance: PhotosData;

    public _photos: { [id: string]: Photo } = {};
    public _keywords: { [id: string]: Keyword } = {};
    public _nameToKeywordId: { [name: string]: string } = {};
    public _recentKeywords: string[] = [];

    static async getInstance(): Promise<PhotosData> {
        if (PhotosData._instance === undefined) {
            const keywordList = await TabularData.fetchFromUrl(PhotosData.KeywordsListUrl, false);
            const photosList = await TabularData.fetchFromUrl(PhotosData.ImageListUrl, false);
            const keywordsImages = await TabularData.fetchFromUrl(PhotosData.KeywordsImagesListUrl, false);
            const recentKeywordsData = await TabularData.fetchFromUrl(PhotosData.RecentKeywordIdListUrl, false);
            PhotosData._instance = new PhotosData(keywordList, keywordsImages, photosList, recentKeywordsData);
        }
        return PhotosData._instance;
    }

    private constructor(keywordList: string[][], keywordsImages: string[][], photosList: string[][], recentKeywordsData: string[][]) {
        photosList.forEach((row: string[]) => {
            const path = row[PhotosImageData.path];
            const pathNoExt = path.substring(0, path.lastIndexOf("."));

            this._photos[row[PhotosImageData.id]] =
            {
                id: row[PhotosImageData.id],
                path: path,
                thumbPath: pathNoExt + ".thumb.jpg",
                width: +row[PhotosImageData.width],
                height: +row[PhotosImageData.height]
            }
        });

        keywordList.forEach((row: string[]) => {
            if (row[PhotosKeywordData.keyword].indexOf("Monnaies") === 0 || row[PhotosKeywordData.keyword] === "No Web")
                return;

            const photoIds: string[] = keywordsImages
                .filter(ki => ki[PhotosKeywordsImagesData.keywordId] === row[PhotosKeywordData.id])
                .map(ki => ki[PhotosKeywordsImagesData.imageId]);

            this._keywords[row[PhotosKeywordData.id]] = { photoIds: photoIds, id: row[PhotosKeywordData.id], name: row[PhotosKeywordData.keyword] };
            this._nameToKeywordId[row[PhotosKeywordData.keyword]] = row[PhotosKeywordData.id];
        });

        this._recentKeywords = recentKeywordsData.map(row => row[0]);
    }

}