import * as React from "react";
import './index.css';
import { PhotosData } from "./PhotosData";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

let _photosData: PhotosData;

export function HomeLayout() {
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            _photosData = await PhotosData.getInstance();
            setHidden(false);
        }
        fetchData();
    }, []);

    if (hidden || _photosData === undefined)
        return null;

    const recents = _photosData._recentKeywords.map(k => _photosData._keywords[k]);

    const keywordAndImage = recents.map(k => ({ keyword: k, oneRandomImage: k.photoIds[Math.floor(Math.random() * Math.floor(k.photoIds.length))] }));

    return <div className='homePage'>
        {keywordAndImage.map(kandi => <div className="homeThumbnails">
            <Link to={"/Photos/" + kandi.keyword.name} >
                <img className="photoThumbnailImg" alt=""
                    src={PhotosData.ThumbnailAzureFolder + _photosData?._photos[kandi.oneRandomImage].thumbPath}
                    title={kandi.keyword.name} />
            </Link>
            <div>{kandi.keyword.name}</div>
        </div>)}
    </div>;
}
