export class TabularData {
    public static async fetchFromUrl(url: string, withHeader: boolean): Promise<string[][]> {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }
        const result = await response.text();

        return TabularData.ParseTsv(result, withHeader);
    }

    private static ParseTsv(response: string, withHeader: boolean): string[][] {
        const lines: string[] = response.replace(/\r\n/g, "\n").split("\n").filter(s => s.length !== 0);

        let data = [];

        for (let i = 0; i < lines.length; i++) {
            if (withHeader && i === 0)
                continue;
            data.push(lines[i].split('\t'));
        }

        return data;
    }
}
