import * as React from "react";
import { TabularData } from "./TabularData";
import { MonnaiesData, MonnaiesDataUtil } from "./MonnaiesData";
import { MonnaiesThumb } from "./MonnaiesThumb";

export interface MonnaiesListProps {
    monnaies: string[][];
    category?: string;
    subcategory?: string;
    showFullSize: (urls: string[], currentIndex: number) => void;
    thumbSize: number;
}

function NaturalValue(value: string): string {
    const padding = '00000000000000000000';

    return value.replace(
        /(\d+)((\.\d+)+)?/g,
        function ($0, integer, decimal, $3) {
            if (decimal !== $3) {
                // treat as a series of integers,
                // rather than a decimal
                return $0.replace(/(\d+)/g, function ($d) { return padding.slice($d.length) + $d });
            }
            else {
                decimal = decimal || ".0";
                return padding.slice(integer.length) + integer + decimal + padding.slice(decimal.length);
            }
        }
    );
}

export function MonnaiesList(props: MonnaiesListProps) {
    if (props.category === undefined)
        return null;

    const filteredAndSortedList =
        props.monnaies
            .filter((row) =>
                props.category === "Nouveau" ? row[MonnaiesData.Nouveau] !== undefined && row[MonnaiesData.Nouveau].length !== 0 :
                    row[MonnaiesData.Type] === props.category + '\\' + props.subcategory)
            .sort((row1, row2) => NaturalValue(row1[MonnaiesData.Name]).localeCompare(NaturalValue(row2[MonnaiesData.Name])));

    const fullSizeUrls: string[] = [];

    let currentIndex = 0;
    filteredAndSortedList.forEach((row: string[]) => {
        row[MonnaiesData.DroitIndex] = (currentIndex++).toString();
        fullSizeUrls.push(row[MonnaiesData.Droit]);
        if (MonnaiesDataUtil.HasRevers(row)) {
            row[MonnaiesData.ReversIndex] = (currentIndex++).toString();
            fullSizeUrls.push(row[MonnaiesData.Revers]);
        }
    });

    return <div className='monnaiesList'>
        {filteredAndSortedList.map(row =>
            <div className='oneMonnaie'>
                <div>{row[MonnaiesData.Name]}</div>
                <MonnaiesThumb
                    fullSizeUrls={fullSizeUrls}
                    hasRevers={MonnaiesDataUtil.HasRevers(row)}
                    droitThumb={row[MonnaiesData.DroitThumb]}
                    reversThumb={row[MonnaiesData.ReversThumb]}
                    droitIndex={+row[MonnaiesData.DroitIndex]}
                    reversIndex={MonnaiesDataUtil.HasRevers(row) ? +row[MonnaiesData.ReversIndex] : 0}
                    description={row[MonnaiesData.Description]}
                    showFullSize={props.showFullSize}
                    thumbSize={props.thumbSize}
                />
            </div>)}
    </div>;
}

