import * as React from "react";
import './index.css';
import { GenealogieData, Individual, Event } from "./GenealogieData";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function RenderIndividual(individual: Individual): JSX.Element {
    return <span><Link to={"/Genealogie/Individu/" + individual?.id}>{individual.lastName} {individual.firstName}</Link> ({individual.getShortDates()}) <i>{individual.job}</i></span>;
}

export function RenderIndividualById(genealogieData: GenealogieData, id: string): JSX.Element {
    const individual = genealogieData._individuals[id];

    if (individual === null || individual === undefined)
        return <span>Individu {id} inconnu</span>;

    return RenderIndividual(individual);
}

function renderEventDate(e: Event): JSX.Element {
    return <span>{e.date.length > 0 && <span> {e.date.length > 4 ? "le" : "en"} {e.date}</span>}</span>;
}

function renderEvent(genealogieData: GenealogieData, e: Event): JSX.Element {
    return <div>
        <div><b>{ e.type }</b>{ !e.isEmpty() && ":" } { renderEventDate(e) } { e.place.length > 0 && <span>à {e.place}</span> }</div>
        { e.note.length != 0 && <div><i>{e.note}</i></div> }
        { e.associations.length > 0 && 
            <ul> {e.associations.map( a => <li>{a.type}{a.note.length > 0 && <span> - {a.note}</span>}: {RenderIndividualById(genealogieData, a.individualId)}</li>)} </ul> }
    </div>
}

function RenderFamily(genealogieData: GenealogieData, id: string, familyId: string): JSX.Element {
    const family = genealogieData._families[familyId];
    const spouseId = family.husbandId === id ? family.wifeId : family.husbandId;
    const renderUnion = <div><b>Union:</b> {RenderIndividualById(genealogieData, spouseId)}</div>;
    const renderEvents = family.events.map(e => renderEvent(genealogieData, e));
    const renderChildren = family.childrenIds.length > 0 && 
        <div><b>Enfants</b><ol>{family.childrenIds.map(id => <li>{RenderIndividualById(genealogieData, id)}</li>)}</ol></div>;
    return <div>{renderUnion}{renderEvents}{renderChildren}<p/></div>
}

function getAncestorsWithLevel(genealogieData: GenealogieData, id: string, currentLevel: number, ancestors: {id: string, level: number}[]) {
    const individual = genealogieData._individuals[id];
    if (individual === null || individual === undefined || !individual.familyIdAsChild)
        return;

    const parentFamily = genealogieData._families[individual.familyIdAsChild];
        if (parentFamily.husbandId != "") {
            ancestors.push({id: parentFamily.husbandId, level: currentLevel + 1});
            getAncestorsWithLevel(genealogieData, parentFamily.husbandId, currentLevel + 1, ancestors);
        }
        if (parentFamily.wifeId != "") {
            ancestors.push({id: parentFamily.wifeId, level: currentLevel + 1});
            getAncestorsWithLevel(genealogieData, parentFamily.wifeId, currentLevel + 1, ancestors);
        }
}

function renderAncestorsOrderedByBirthPlace(genealogieData: GenealogieData, id: string): JSX.Element|null {
    let ancestors: {id: string, level: number}[] = [];
    getAncestorsWithLevel(genealogieData, id, 0, ancestors);

    let ancestorPerBirthPlace: { [birthPlace: string]: {id: string, level: number}[] } = {};

    ancestors.forEach(a => {
        const individual = genealogieData._individuals[a.id];
        const birth = individual.getBirthEvent();
        if (birth != undefined && birth.place.length > 0) {
            if (birth.place in ancestorPerBirthPlace)
                ancestorPerBirthPlace[birth.place].push({id: a.id, level: a.level});
            else
                ancestorPerBirthPlace[birth.place] = [{id: a.id, level: a.level}];
        }
    });
    const ancestorPerBirthPlaceArray = Object.entries(ancestorPerBirthPlace).sort(
        (a, b) => a[0].localeCompare(b[0]) );

    if (ancestorPerBirthPlaceArray.length === 0)
        return null;
    
    const lieuxRender: JSX.Element = 
        <div> {
            ancestorPerBirthPlaceArray.map(a => 
                <div><b>{a[0]}</b><ul>{a[1].sort((a, b) => a.level - b.level).map(i => <li>{i.level} {RenderIndividualById(genealogieData, i.id)}</li>)}</ul><p/></div>
            )
        }
        </div>

    return <div><h2>Ancètres par lieu</h2>{lieuxRender}</div>;
}

export function GenealogieIndividuLayout() {
    const id = useParams()["id"] as string;

    const [genealogieData, setGenealogieData] = useState<GenealogieData>();
    useEffect(() => {
      const fetchData = async () => { setGenealogieData(await GenealogieData.getInstance()); };
      fetchData();
    }, []); 

    if (genealogieData === undefined)
        return <span>Chargement des données...</span>;

    const individual = genealogieData._individuals[id];
    if (individual === undefined)
        return <span>Individu inconnu</span>;

    const renderIndividual = 
    <div>
        <h1>{individual.lastName} {individual.firstName}</h1>
        <div>{individual.job}</div><p/>
        {individual.events.map(e => renderEvent(genealogieData, e))}
    </div>;

    let renderParents: JSX.Element = <div/>;
    if (individual.familyIdAsChild) {
        const parentFamily = genealogieData._families[individual.familyIdAsChild];
        renderParents = <div>
            <div><b>Père:</b> {RenderIndividualById(genealogieData, parentFamily.husbandId)}</div>
            <div><b>Mère:</b> {RenderIndividualById(genealogieData, parentFamily.wifeId)}</div>
            </div>;
    }

    const renderFamilies: JSX.Element[] = individual.familyIdsAsSpouse.map(familyId => <div>{RenderFamily(genealogieData, id, familyId)}</div>);

    return <div className='genealogieIndividualPage'>{renderIndividual}{renderParents}{renderFamilies}{renderAncestorsOrderedByBirthPlace(genealogieData, id)}</div>
};

